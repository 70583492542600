<template>
  <div class="container">
    <div class="apartments__wrapper screen__wrapper">
      <CardApartment
        v-for="apartment in apartments"
        :key="apartment.id"
        :obj="apartment"
        @click.native="info(apartment)"
      />
    </div>
  <infinite-loading v-if="!isLoading && hasMoreResults" @infinite="loadObj"></infinite-loading>
  </div>
</template>

<script>
import CardApartment from '@/views/Rent/Card'
import InfiniteLoading from 'vue-infinite-loading'
import InfoRent from '@/views/Rent/Info'

export default {
  name: "FavoriteRentApartments",
  components: {
    CardApartment,
    InfiniteLoading
  },
  data: () => ({
    isLoading: false,
  }),
  computed: {
    apartments() {
      return this.$store.getters['favorites/rent/apartments'];
    },
    hasMoreResults () { 
      return this.apartments.length < this.totalCount && this.$store.getters.page > 1
    },
    totalCount() {
      return this.$store.getters['favorites/rent/apartmentsPagination'].total
    }, 
    page() {
      return this.$store.getters['favorites/rent/apartmentsCurrentPage']
    }
  },
  methods: {
    async loadObj() {
      this.isLoading = true
      try {
        await this.$store.dispatch('favorites/rent/fetch', 'apartment')
        this.$emit('loaded')
      } finally {
        this.isLoading = false
      }
    },
    info(apt) {
      this.$modal.show(
        InfoRent,
        {info: apt}, 
        {name: 'InfoRent', transition: 'none', classes: 'modal', width: '900px', height: '92%'},
        {'before-open': this.$modal.open,'before-close': this.$modal.close}
      );
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
@import "./style.scss";
</style>
